<template>
  <v-slider
    v-model="val"
    :label="label"
    :readonly="!editable"
    :step="isSummary ? .1 : 1"
    :tick-size="editable ? 4 : 0"
    thumb-label="always"
    min="1"
    max="10"
    ticks="always">
    <template v-slot:label>
      <div style="width:200px">{{ label }}</div>
    </template>
  </v-slider>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    isSummary: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      default: 1
    }
  },
  setup (props, { emit }) {
    const val = ref(props.value)

    watch(val, (val) => {
      if (val !== props.value) {
        emit('change', val)
      }
    })

    return {
      val
    }
  }
}
</script>
